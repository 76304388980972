import { useState, useEffect } from 'react';
import HealthOverviewContext from './HealthOverviewContext';
import { SpaceIOService } from 'auth-utils'
import { PageLoader} from "freespace-react-components";



interface Item {
    id: string;
    name: string;
    type: string;
    showInMenu: boolean;
    locations?: Item[];
    healthInfo: object;
    totalDeviceCount: object;
}

interface TransformedItem {
    key: string;
    data: {
        liveStatus: boolean;
        name: string;
        Id: string;
        Type: string;
        [key: string]: any; // Allow additional dynamic keys
    };
    children?: TransformedItem[];
}

const HealthOverviewProvider = ({ children }) => {
    // const [locationsInfo, setLocationsInfo] = useState(null)
    const [quickInfoData, setQuickInfoData] = useState({})
    const [overallInfo, setOverallInfo] = useState(null);
    const [faultyFloors, setFaultyFloors] = useState();
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [liveTreeAPIData, setLiveTreeAPIData] = useState(null);
    const [riskViewValue, setRiskViewValue] = useState(true);
    const [Products, setProducts] = useState([]);


    let productTypes=[];


    const fetchProducts =  async () =>{
        const init = {
            method: 'get',
            maxBodyLength: Infinity,
            url: `${process.env.PROD_WS_BASE_URL}/api/admin/searchProducts`,
            headers: {
                'accept': 'application/json, text/javascript, */*; q=0.01',
            }
        };
        try {
            const response = await SpaceIOService.request(
                `${process.env.PROD_WS_BASE_URL}/api/admin/searchProducts`
                , init
            );
            const responseData = await response.json();
            return responseData;
        } catch (error) {
            console.error('An error occurred in the axios request:', error);
        }
    }
    
   
    // async function transformLocations(data: Item[],productTypes): Promise<TransformedItem[]> {     
          
    //     const transformedData = await Promise.all(data.map(async item => {
    //         const transformed: TransformedItem = {
    //             key: item.id.toString(),
    //             data: {
    //                 liveStatus: item.showInMenu ?? false,
    //                 name: item.name,
    //                 Id: item.id,
    //                 Type: item.type,
    //             }
    //         };
             
    //         if (item.type === 'FLOOR' && item.healthInfo) {
    //             transformed.data['Overall'] =  item['overallPercentage'] = (item['overallPercentage'] === Infinity || item['overallPercentage'] === -Infinity) 
    //             ? 0 
    //             : item['overallPercentage'] || 0;
    
    //             const renderMetric = (metric: string) => {
    //                 const total = item.totalDeviceCount?.[metric] || 0;
    //                 const health = item.healthInfo?.[metric] || 0;
    //                 const difference = total - health;
    
    //                 return riskViewValue ? (
    //                     <div className='flex items-center gap-2'>
    //                         <p className='cursor-pointer'>{total}</p>
    //                         <p style={{color:'#FF5E5E'}} className='cursor-pointer rounded text-white px-1 py-0.5'>{difference}</p>
    //                     </div>
    //                 ) : (
    //                     <div className='flex items-center gap-2'>
    //                         <p className='cursor-pointer'>{total}</p>
    //                         <p style={{color:'#38D68B'}} className='cursor-pointer rounded text-white px-1 py-0.5'>{health}</p>
    //                     </div>
    //                 );
    //             };
    
    //             productTypes.forEach(metric => {
    //                 transformed.data[metric] = renderMetric(metric);
    //             });
    //         }
    
    //         if (item.type === 'BUILDING') {
    //             productTypes.forEach(metric => {
    //                 transformed.data[metric] = 0;
    //             });
    //             transformed.data['overall'] = 0;
    
    //             item.locations?.forEach(location => {
    //                 if (location.type === 'FLOOR') {
    //                     productTypes.forEach(product => {
    //                         transformed.data[product] += location.totalDeviceCount?.[product] || 0;
    //                     });
    //                     transformed.data['overall'] += location['overallPercentage'] || 0;
    //                 }
    //             });
    //         }
    
    //         if (item.locations && item.locations.length > 0) {
    //             transformed.children = await transformLocations(item.locations,productTypes); // Recursively transform nested locations
    //         }
    
    //         return transformed;
    //     }));
    
    //     return transformedData;
    // }

    async function transformLocations(data: Item[], productTypes: string[]): Promise<TransformedItem[]> {
        let keyCounter = 0;  // Helper to generate unique keys dynamically
    
        // Helper function to generate unique keys for nested items
        const generateKey = (parentKey: string, index: number) => {
            return `${parentKey}-${index}`;
        };
    
        // Recursive function to transform the locations and their children
        const transformNode = async (item: Item, parentKey: string): Promise<TransformedItem> => {
            const transformed: TransformedItem = {
                key: generateKey(parentKey, keyCounter++),
                data: {
                    Name: item.name,
                    Type: item.type,
                    Id: item.id.toString(),
                    liveStatus: item.showInMenu ?? false,
                    name: ''
                },
                children: [],
            };
    
            // Handle 'FLOOR' type nodes
            if (item.type === 'FLOOR' && item.healthInfo) {
                transformed.data['Overall'] = item['overallPercentage'] === Infinity || item['overallPercentage'] === -Infinity
                    ? 0
                    : item['overallPercentage'] || 0;
    
                const renderMetric = (metric: string) => {
                    const total = item.totalDeviceCount?.[metric] || 0;
                    const health = item.healthInfo?.[metric] || 0;
                    const difference = total - health;
    
                    return (
                        <div className='flex items-center gap-2'>
                            <p className='cursor-pointer'>{total}</p>
                            <p style={{color: '#FF5E5E'}} className='cursor-pointer rounded text-white px-1 py-0.5'>{difference}</p>
                        </div>
                    );
                };
    
                // Render all product metrics for floor
                productTypes.forEach(metric => {
                    transformed.data[metric] = renderMetric(metric);
                });
            }
    
            // Handle 'BUILDING' type nodes (aggregate data from child floors)
            if (item.type === 'BUILDING') {
                productTypes.forEach(metric => {
                    transformed.data[metric] = 0;
                });
                transformed.data['Overall'] = 0;
    
                // Aggregate data from child locations (floors)
                item.locations?.forEach(location => {
                    if (location.type === 'FLOOR') {
                        productTypes.forEach(product => {
                            transformed.data[product] += location.totalDeviceCount?.[product] || 0;
                        });
                        transformed.data['Overall'] += location['overallPercentage'] || 0;
                    }
                });
            }
    
            // Handle recursive nesting of children (locations or buildings)
            if (item.locations && item.locations.length > 0) {
                // Recursively process the children locations
                for (let i = 0; i < item.locations.length; i++) {
                    const childItem = item.locations[i];
                    const childKey = generateKey(transformed.key, i);
                    const transformedChild = await transformNode(childItem, transformed.key);
                    transformed.children.push(transformedChild);
                }
            }
    
            return transformed;
        };
    
        // Transform all the root-level items
        const transformedData = await Promise.all(data.map(async (item, index) => {
            const parentKey = `${index}`; // root level key starts as "0", "1", etc.
            return await transformNode(item, parentKey);
        }));
    
        return transformedData;
    }
    
    
    useEffect(() => {
        const getData = async () => {
            try {
                const products = await fetchProducts()
                products.map(item => {
                    if(item.name == "QSense" || item.name == "FBG" || item.name == "ROS" || item.name == "Enclosed"|| item.name == undefined || item.name == 'POE TIM+' || item.name == 'POE TIM' || item.name == 'POE FLO' || item.name == 'ONE-M'){
                        return;
                    }
                        productTypes.push(item.name); 
                })
                const data = await fetchAccountLocationData(productTypes);
                const faultyFloors = await filterFaultyFloors(data.floorData)
                const transformedData: TransformedItem[] = await transformLocations([data.treeData],productTypes);
                setFaultyFloors(faultyFloors)
                setLiveTreeAPIData(transformedData)
                // setLocationsInfo(data.floorData)
                setQuickInfoData(data.productLevelDeviceHealth);
                setProducts(productTypes)
                
                setLoading(false);
            } catch (error) {
                throw (error)
            }
        }
        getData()
    }, [riskViewValue])

    const filterFaultyFloors = async (floors) => {
       
        floors.map(item => {
            let totalDevices = 0;
            let totalHealthyDevices = 0;

            if(item.totalDeviceCount){
                Object.keys(item.totalDeviceCount).forEach(key => {
                    if (
                        key !== 'utcEpoch' &&
                        key !== 'tzEpoch' &&
                        key !== 'processedAt' &&
                        key !== 'id'
                    ) {
 
                        totalDevices += item.totalDeviceCount[key];
                    }
                });
            }

            if(item.healthData){
                Object.keys(item.healthData).forEach(key => {
                    if (
                        key !== 'utcEpoch' &&
                        key !== 'tzEpoch' &&
                        key !== 'processedAt' &&
                        key !== 'id'
                    ) {
 
                        totalHealthyDevices += item.healthData[key];
                    }
                });
            }

            const totalOverall = totalDevices - totalHealthyDevices;
            const faultyPercentage = totalDevices > 0 ? (totalOverall / totalDevices) * 100 : 0;
            item.faultyPercentage = Math.floor(faultyPercentage);
            
            
        });

        const sortedArray = floors.sort((a, b) => b.faultyPercentage - a.faultyPercentage);
        let faultyFloors = sortedArray.slice(0, 4);
        return faultyFloors;                

    }

    const treeApiData = async () => {

        const init = {
            method: 'get',
            maxBodyLength: Infinity,
            url: `${process.env.PROD_WS_BASE_URL}/api/locations/tree`,
            headers: {
                'accept': 'application/json, text/javascript, */*; q=0.01',
            }
        };
        try {
            const response = await SpaceIOService.request(
                `${process.env.PROD_WS_BASE_URL}/api/locations/tree`
                , init
            );
            const responseData = await response.json();
            return responseData;
        } catch (error) {
            console.error('An error occurred in the axios request:', error);
        }

    };

    const fetchHealthDataFloorWise = async (arrayOfFloorAssetId: string[]) => {
        if (arrayOfFloorAssetId.length > 0) {
            let data = JSON.stringify(arrayOfFloorAssetId);
            let config = {
                method: 'post',
                maxBodyLength: Infinity,
                url: `${process.env.SPOT_BASE_URL}/assets/data/${process.env.TOTAL_NO_OF_HEALTHY_DEVICES}/current`,
                body: data
            };
            try {
                const response = await SpaceIOService.request(
                    `${process.env.SPOT_BASE_URL}/assets/data/${process.env.TOTAL_NO_OF_HEALTHY_DEVICES}/current`
                    , config,true,true   
                );
                return await response.json();
            } catch (error) {
                console.error('An error occurred in the axios request:', error);
            }
        }
        else { return [] }
    };

    const fetchTOtalDeviceDataFloorWise = async (arrayOfFloorAssetId: string[]) => {
        if (arrayOfFloorAssetId.length > 0) {
            let data = JSON.stringify(arrayOfFloorAssetId);
            let config = {
                method: 'post',
                maxBodyLength: Infinity,
                url: `${process.env.SPOT_BASE_URL}/assets/data/${process.env.TOTAL_NO_OF_DEVICES}/current`, 
                body: data
            };
            try {
                const response = await SpaceIOService.request(
                    `${process.env.SPOT_BASE_URL}/assets/data/${process.env.TOTAL_NO_OF_DEVICES}/current`
                    , config,true,true   
                );
                return await response.json();
            } catch (error) {
                console.error('An error occurred in the axios request:', error);
            }
        }
        else { return [] }
    };

    const fetchAccountLocationData = async (productTypes) => {
    
        const init = {
            method: 'get',
            maxBodyLength: Infinity,
            url: `${process.env.PROD_WS_BASE_URL}/api/locations?detailed=false`,
            headers: {
                'accept': 'application/json, text/javascript, */*; q=0.01',
            }
        }
    
        try {
            const treeData = await treeApiData();
            const response = await SpaceIOService.request(`${process.env.PROD_WS_BASE_URL}/api/locations?detailed=false`, init);
            const responseData = await response.json();
            let floorData = [];
            let FloorAssetIds = [];
    
            // Collect FloorAssetIds
            await Promise.all(
                responseData
                    .filter(item => item.type === 'FLOOR')
                    .map(async (item: any) => {
                        FloorAssetIds.push(item.assetId);
                    })
            );
    
            // Initialize product-level device health
            let productLevelDeviceHealth = productTypes.reduce((acc, metric) => {
                acc[metric] = { healthy: 0, total: 0 };
                return acc;
            }, {});
    
            const arrayOfHealtyDeviceAcount = await fetchHealthDataFloorWise(FloorAssetIds);
            const arrayOfTotalDeviceAcount = await fetchTOtalDeviceDataFloorWise(FloorAssetIds);
    
            // Collect floor data
            await Promise.all(
                responseData
                    .filter(item => item.type === 'FLOOR')
                    .map(async (item: any) => {
                        floorData.push(item);
                    })
            );
    
            // Merge health data into raw data if conditions are met
            floorData.forEach(rawItem => {
                const healthItem = arrayOfHealtyDeviceAcount.find(health => health.id === rawItem.assetId && health.hasOwnProperty('utcEpoch'));
                const deviceTotalCountItem = arrayOfTotalDeviceAcount.find(deviceTotalCount => deviceTotalCount.id === rawItem.assetId && deviceTotalCount.hasOwnProperty('utcEpoch'));
    
                if (healthItem && deviceTotalCountItem) {
                    let overallValueHealth = Object.keys(healthItem).reduce((sum, key) => {
                        if (!['utcEpoch', 'tzEpoch', 'processedAt', 'id'].includes(key)) {
                            return sum + healthItem[key];
                        }
                        return sum;
                    }, 0);
    
                    let overallValueTotal = Object.keys(deviceTotalCountItem).reduce((sum, key) => {
                        if (!['utcEpoch', 'tzEpoch', 'processedAt', 'id'].includes(key)) {
                            return sum + deviceTotalCountItem[key];
                        }
                        return sum;
                    }, 0);
    
                    let overallPercentage = Math.floor(parseFloat(((overallValueHealth / overallValueTotal) * 100).toFixed(2)));
    
                    rawItem.healthData = healthItem;
                    rawItem.totalDeviceCount = deviceTotalCountItem;
                    rawItem.overallPercentage = overallPercentage;
                }
            });
    
            // Function to arrange the data
            const arrangeData = (data: any[]): void => {
                data.forEach(item => {
                    if (item.type === 'FLOOR') {
                        const maininfo = floorData.find(floorInfo => floorInfo.id === item.id);
                        if (maininfo) {
                            item.healthInfo = maininfo.healthData || null;
                            item.totalDeviceCount = maininfo.totalDeviceCount || null;
                            item.overallPercentage = maininfo.overallPercentage || null;
                        }
                    }
    
                    // Recursively handle nested locations
                    if (item.locations) {
                        arrangeData(item.locations);
                    }
                });
            };
    
            arrangeData(treeData.locations);
    
            // Initialize floor-level data
            let floorLevelDeviceHealth = productTypes.reduce((acc, metric) => {
                acc[metric] = 0;
                return acc;
            }, { overall: 0 });
    
            let floorLevelTotalDevices = productTypes.reduce((acc, metric) => {
                acc[metric] = 0;
                return acc;
            }, { overall: 0 });
    
            let floorLevelDeviceHealthPercentage = productTypes.reduce((acc, metric) => {
                acc[metric] = 0;
                return acc;
            }, { overall: 0 });
    
            // Calculate device health and totals
            arrayOfHealtyDeviceAcount.forEach((floorWiseHealthDevice: any) => {
                productTypes.forEach(metric => {
                    if (floorWiseHealthDevice.hasOwnProperty(metric)) {
                        floorLevelDeviceHealth[metric] += floorWiseHealthDevice[metric];
                    }
                });
            });
    
            arrayOfTotalDeviceAcount.forEach((floorWiseTotalDevice: any) => {
                productTypes.forEach(metric => {
                    if (floorWiseTotalDevice.hasOwnProperty(metric) && floorWiseTotalDevice[metric] !== 0) {
                        floorLevelTotalDevices[metric] += floorWiseTotalDevice[metric];
                    }
                });
            });
    
            // Calculate percentage health for each device type
            productTypes.forEach(metric => {
                if (floorLevelTotalDevices[metric] > 0) {
                    floorLevelDeviceHealthPercentage[metric] = parseFloat(((floorLevelDeviceHealth[metric] / floorLevelTotalDevices[metric]) * 100).toFixed(2));
                } else {
                    floorLevelDeviceHealthPercentage[metric] = null;
                }
            });
    


            let overallHealthyDevices = (Object.values(floorLevelDeviceHealth) as number[])
            .reduce((acc, val) => acc + val, 0);
        
            let overallTotalDevices = (Object.values(floorLevelTotalDevices) as number[])
            .reduce((acc, val) => acc + val, 0);
        

    
            if (overallTotalDevices > 0) {
                floorLevelDeviceHealthPercentage.overall = parseFloat(((overallHealthyDevices / overallTotalDevices) * 100).toFixed(2));
                setOverallInfo(floorLevelDeviceHealthPercentage.overall);
            } else {
                floorLevelDeviceHealthPercentage.overall = null;
                setOverallInfo(floorLevelDeviceHealthPercentage.overall);
            }
    
            // Accumulate health and total devices for each product
            productTypes.forEach(metric => {
                productLevelDeviceHealth[metric].healthy += floorLevelDeviceHealth[metric];
                productLevelDeviceHealth[metric].total += floorLevelTotalDevices[metric];
            });
    
            return {
                productLevelDeviceHealth,
                floorData,
                treeData
            };
    
        } catch (e) {
            setError(error);
            setLoading(false);
        }
    };
    

    if (loading) {
        return (
            <PageLoader
                loading={true}
            />
        );
    }

    if (error) {
        return <div>Error: {error}</div>;
    }

    return (
        <HealthOverviewContext.Provider value={{
            overallInfo: overallInfo,
            liveTreeAPIData: liveTreeAPIData,
            insightText: faultyFloors,
            quickInfoData: quickInfoData,
            Products: Products,
        }}>
            {children}
        </HealthOverviewContext.Provider >
    );
}

// Skip prop validation by setting propTypes to null
HealthOverviewProvider.propTypes = null;

export default HealthOverviewProvider;