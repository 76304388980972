import { useState, useEffect, useContext, ReactElement, JSXElementConstructor, ReactNode, ReactPortal } from 'react'
import { InsightText, Table, Tag, Gauge, Button, Switch } from "freespace-react-components";
import HealthOverviewContext from '../../context/HealthOverviewContext';
import { useNavigate } from 'react-router-dom';
import { SpaceIOService } from 'auth-utils'

interface ColumnMeta {
    field: string;
    header: string;
    fixShowCol?: boolean;
    sortable: boolean;
    frozen?: boolean;
    sortValue: string | null | ((rowData: any) => any);
    colBody: string | number | boolean | ReactElement<any, string |
        JSXElementConstructor<any>> | Iterable<ReactNode> |
    ReactPortal | ((data: any, options: any) => React.ReactNode) | null | undefined;
    alignFrozen?: 'right' | 'left' | undefined;
}

const HealthOverview = () => {
    const navigate = useNavigate();

    const { accountsInfo } = useContext<any>(HealthOverviewContext)
    const { insightTextData } = useContext<any>(HealthOverviewContext)
    const { quickInfoData } = useContext<any>(HealthOverviewContext)

    const [isRiskView, setIsRiskView] = useState(true);
    const [showQuickInfo, setShowQuickInfo] = useState(false);
    const [averagePercentages, setAveragePercentages] = useState<object>({})




    const tagBodyTemplate = (prop: any) => {

        if (prop.value != null) {
            let severity = null;
            if (prop.value <= 5) {
                severity = 'success';
            } else if (prop.value > 6 && prop.value < 15) {
                severity = 'warning';
            } else {
                severity = 'danger'
            }
            return <Tag value={prop.value} severity={severity} />
        }
    };

    // const textBodyTemplate = (prop: any) => {
    //     let colour = 'black';
    //     let value = prop.value

    //     if (prop.isRiskView) {
    //         if (typeof (value) != 'string') {
    //             if (value <= 5) {
    //                 colour = 'black';
    //             } else if (value > 6 && value <= 15) {
    //                 colour = 'amber';
    //             } else {
    //                 colour = 'error'
    //             }
    //         }
    //     }

    //     return (
    //         <div>
    //             <span className={`text-${colour}`}>
    //                 {value}
    //             </span>
    //         </div>
    //     )
    // };

    const numberBodyTemplate = (value: any) => {
        let colour = 'black';
        if (value <= 5) {
            colour = 'black';
        } else if (value > 6 && value < 15) {
            colour = 'lime-400';
        } else {
            colour = 'error'
        }

        return (
            <span className={`text-${colour}`}>
                {value}
            </span>
        )
    };

    const stringBodyTemplate = (value: any) => {
        return (
            <span>
                {value}
            </span>
        )
    };

    const imageBodyTemplate = (prop: any) => {
        return <img
            src={prop.src}
            alt={prop.alt}
            className=" w-auto h-6"
        />;
    };

    // const complexTextBodyTemplate = (prop: any) => {
    //     return (
    //         <div>
    //             <p>{prop.name}</p>
    //             <p className='text-secondaryText'> ID :{prop.value}</p>
    //         </div>
    //     );
    // }

    // const renderActionIcons = (actions: any, rowData: any) => {
    //     return actions?.map((action: any, index: number) => <>
    //         <span
    //             key={`table-action-key-${index}`}
    //             className={`table-action-key-${index} text-primaryAction ${action.icon}`}
    //             onClick={() => action.onClick(rowData)}
    //         />
    //         {/* {action.label && <Tooltip event={'hover'} position={'bottom'} target={`.table-action-key-${index}`}
    //             children={action.label} />} */}
    //     </>);
    // };

    // const actionBodyTemplate = (rowData: any) => {
    //     return (
    //         <span className={'flex w-full items-center justify-start gap-2 mx-2'}>
    //             {renderActionIcons(rowData.actions, rowData)}
    //         </span>
    //     );
    // };

    const columns: ColumnMeta[] = [
        {
            field: 'logo',
            header: 'Logo',
            fixShowCol: true,
            sortable: false,
            frozen: true,
            sortValue: (rowData: any) => (rowData.logo),
            colBody: (rowData: any) => imageBodyTemplate({ src: rowData.logo, alt: rowData['Account Name'] })
        },
        // {
        //     field: 'Account Id',
        //     header: 'Account Info',
        //     sortable: true,
        //     sortValue: (rowData: any) => (rowData['Account Id']),
        //     colBody: (rowData: any) => complexTextBodyTemplate({
        //         name: rowData['Account Name'],
        //         value: rowData['Account Id']
        //     })
        // },
        {
            field: 'Accounts',
            header: 'Accounts',
            fixShowCol: true,
            sortable: true,
            sortValue: (rowData: any) => (rowData['Accounts']),
            colBody: (rowData: any) => stringBodyTemplate(rowData['Accounts'])
        },
        {
            field: 'Id',
            header: 'Id',
            fixShowCol: true,
            sortable: true,
            sortValue: (rowData: any) => (rowData['Id']),
            colBody: (rowData: any) => (<p>{rowData['Id']}</p>)
        },
        {
            field: 'Overall',
            header: 'Overall',
            fixShowCol: true,
            sortable: true,
            sortValue: (rowData: any) => (rowData.Overall),
            colBody: (rowData: any) => tagBodyTemplate({
                severity: rowData.Overall > 80 && rowData.Overall < 100 ? 'success' : rowData.Overall > 60 ? 'warning' : 'danger',
                value: rowData.Overall
            }),
        },
        {
            field: 'ONE',
            header: 'ONE',
            sortable: true,
            sortValue: (rowData: any) => (rowData.ONE),
            colBody: (rowData: any) => numberBodyTemplate(rowData.ONE)
        },
        {
            field: 'TIM',
            header: 'TIM',
            sortable: true,
            sortValue: (rowData: any) => (rowData.TIM),
            colBody: (rowData: any) => numberBodyTemplate(rowData.TIM)
        },
        {
            field: 'FLO',
            header: 'FLO',
            sortable: true,
            sortValue: (rowData: any) => (rowData['FLO']),
            colBody: (rowData: any) => numberBodyTemplate(rowData['FLO'])
        },
        {
            field: 'BAT-TIM',
            header: 'BAT- TIM',
            sortable: true,
            sortValue: (rowData: any) => (rowData.TIM),
            colBody: (rowData: any) => numberBodyTemplate(rowData['BAT-TIM'])
        },
        {
            field: 'BAT-TIM+',
            header: 'BAT-TIM+',
            sortable: true,
            sortValue: (rowData: any) => (rowData['BAT-TIM+']),
            colBody: (rowData: any) => numberBodyTemplate(rowData['BAT-TIM+'])
        },
        {
            field: 'PAL',
            header: 'PAL',
            sortable: true,
            sortValue: (rowData: any) => (rowData['PAL']),
            colBody: (rowData: any) => numberBodyTemplate(rowData['PAL'])
        },
        {
            field: 'PAM',
            header: 'PAM',
            sortable: true,
            sortValue: (rowData: any) => (rowData['PAM']),
            colBody: (rowData: any) => numberBodyTemplate(rowData['PAM'])
        },
        // {
        //     field: 'risk',
        //     header: 'Risk',
        //     sortable: true,
        //     frozen: true,
        //     alignFrozen: 'right',
        //     sortValue: (rowData: any) => (rowData.risk),
        //     colBody: (rowData: any) => tagBodyTemplate({
        //         severity: 'info',
        //         value: rowData.risk
        //     }),
        // },
        // {
        //     field: 'actions',
        //     header: 'Action',
        //     sortable: false,
        //     frozen: true,
        //     alignFrozen: 'right',
        //     sortValue: null,
        //     colBody: (rowData: any) => actionBodyTemplate(rowData),
        // },
    ];



    const accountInfoData = accountsInfo.map((account, index) => {
        const Overall_value = account.accountLevelfaultyDevices.Overall === null ? null : 100 - account.accountLevelfaultyDevices.Overall;
        const oneValue = isRiskView ? account.accountLevelfaultyDevices.ONE : account.accountLevelDeviceHealth.ONE;
        const timValue = isRiskView ? account.accountLevelfaultyDevices.TIM : account.accountLevelDeviceHealth.TIM;
        const timPlusValue = isRiskView ? account.accountLevelfaultyDevices['TIM+'] : account.accountLevelDeviceHealth['TIM+'];
        const batTimValue = isRiskView ? account.accountLevelfaultyDevices['BAT-TIM'] : account.accountLevelDeviceHealth['BAT-TIM'];
        const batTimPlusValue = isRiskView ? account.accountLevelfaultyDevices['BAT-TIM+'] : account.accountLevelDeviceHealth['BAT-TIM+'];
        const batFloValue = isRiskView ? account.accountLevelfaultyDevices['BAT-FLO'] : account.accountLevelDeviceHealth['BAT-FLO'];
        const palValue = isRiskView ? account.accountLevelfaultyDevices.PAL : account.accountLevelDeviceHealth.PAL;
        const pamValue = isRiskView ? account.accountLevelfaultyDevices.PAM : account.accountLevelDeviceHealth.PAM;
        const floValue = isRiskView ? account.accountLevelfaultyDevices.FLO : account.accountLevelDeviceHealth.FLO;

        const objectData = {
            logo: account.logoUrl,
            Id: account.id,
            Accounts: account.name,
            liveStatus: account.showInMenu,
            Overall: Overall_value,
            ONE: oneValue,
            TIM: timValue,
            FLO: floValue,
            'TIM+': timPlusValue,
            'BAT-TIM': batTimValue,
            'BAT-TIM+': batTimPlusValue,
            'BAT-FLO': batFloValue,
            PAL: palValue,
            PAM: pamValue,
        }

        return objectData
    });

    useEffect(() => {
        setAveragePercentages(quickInfoData)
        // console.log(accountsInfo)
    }, [averagePercentages])


    const handleClick = async (accountId: number) => {
        await SpaceIOService.switchAccount(accountId);
        navigate('/account-health-overview')
    }

    const handleSelectedCell = async (cell) => {
        if (cell.cellIndex < 3) {
            handleClick(cell.rowData['Id'])
        } else if (cell.cellIndex > 3) {
            await SpaceIOService.switchAccount(cell.rowData['Id']);
            navigate('/device-health', {
                state: {
                    ProductType: cell.field
                }
            })
        }
    };

    return (
        <div className=' flex flex-auto overflow-hidden'>
            {/* Main content */}
            <div className={`${showQuickInfo ? 'md:w-4/5 lg:w-4/5 w-full' : 'w-full'} h-full flex flex-col `}>
                {/* InsightText */}
                <div className='flex p-2 justify-between min-h-16 flex-initial bg-accent1Rollover 
                        drop-shadow-[0_10px_10px_rgb(0,0,0,0.1)]'>
                    {insightTextData.map(item => (
                        <InsightText
                            key={item.id}
                            primaryText={
                                <div>
                                    <p className='text-xs text-primaryText'>{`${item.AccountName} - ${item.anchorAddress.split(',')[0]?.trim()}`}</p>
                                </div>
                            }
                            secondaryText={
                                <div className='flex flex-col text-xs'>
                                    <p className='text-xs text-secondaryText '>{`${item.anchorAddress.split(',')[1]?.trim()} - ${item.name}`}</p>
                                </div>
                            }
                            contentInput={
                                <p className="text-xs text-error">
                                    {item.TotalFaulty > 300 ? '70' : item.TotalFaulty > 160 ? '30' : '20'}%
                                </p>
                            }
                        />
                    ))}
                </div>
                {/* End of InsightText */}

                {/* table */}
                <div className='flex-auto overflow-hidden mt-6'>
                    <Table
                        data={accountInfoData}
                        columns={columns}
                        minWidth={'80rem'}
                        multiSelectFilter={[
                            {
                                key: 'Accounts',
                                placeholder: 'Account Name',
                                searchFilter: true,
                                border:false,
                                option: accountInfoData,
                                lableKey: '',
                            }
                        ]}
                        dropDownFilter={[{
                            key: 'liveStatus',
                            lableKey: 'lable',
                            placeholder: 'Live Status',
                            searchFilter: false,
                            defaultOptionValue: [true],
                            option: [
                                { liveStatus: [true], lable: 'Active' },
                                { liveStatus: [false], lable: 'Archive' },
                                { liveStatus: [true, false], lable: 'All' },
                            ],
                        }]}
                        setSelectedCell={handleSelectedCell}
                        extraTableHeaderElement={[
                            <div key="in-risk-view" className='flex justify-center items-center gap-2'>
                                <p className='text-xs lg:text-sm font-normal'>In-Risk View</p>
                                <Switch
                                    checkedStatus={isRiskView}
                                    onChange={(isChecked) => {
                                        setIsRiskView(isChecked);
                                    }} />
                            </div>,
                            <div key="clear-filter" className='flex justify-center items-center gap-2'>
                                <Button
                                    size="small"
                                    icon='pi pi-sparkles'
                                    onClick={() => {
                                        setShowQuickInfo(!showQuickInfo);
                                    }} />
                            </div>
                        ]}
                        globalFilterFields={['Id', 'Accounts']}
                        downloadOption={false}
                        pagination={true}
                        numberOfRows={20}
                        rowsPerPageOptions={[25, 50, 100]}
                    />
                </div>
                {/* End table */}
            </div>
            {/* quick info */}
            {
                showQuickInfo &&
                <div className='md:w-1/5 lg:w-1/5 w-0 p-5 bg-webBg'>
                    <p className=' mb-4 font-bold'>Quick Info</p>
                    <div className=' flex flex-col gap-6 font-sans text-xs'>
                        {
                            Object.keys(averagePercentages).map(key => (
                                <div className='flex gap-6 items-center' key={key}>
                                    <div className=' w-20'>
                                        <Gauge
                                            percentage={
                                                parseFloat(((averagePercentages[key]?.healthy / averagePercentages[key]?.total) * 100).toFixed(2)) > 100 ||
                                                    parseFloat(((averagePercentages[key]?.healthy / averagePercentages[key]?.total) * 100).toFixed(2)) < 0 ? 0 : parseFloat(((averagePercentages[key]?.healthy / averagePercentages[key]?.total) * 100).toFixed(2))
                                            } // Set percentage based on the average percentage for the key
                                            lableOn={false}
                                        />
                                    </div>
                                    <div>
                                        <p className=" text-primaryText font-bold">{averagePercentages[key]?.total > 0 ? parseFloat(((averagePercentages[key]?.healthy / averagePercentages[key]?.total) * 100).toFixed(2)) : 0}%  {key}  </p>
                                        <p className={'text-gray-400'} >{averagePercentages[key]?.healthy}/{averagePercentages[key]?.total}</p>
                                    </div>
                                </div>
                            ))
                        }


                        {/* <div className='flex gap-6 items-center'>
                            <div className=' w-20'>
                                <Gauge
                                    percentage={90}
                                    lableOn={false}
                                />
                            </div>
                            <div>
                                <p className=' font-bold'>TIM</p>
                                <p>+2% Improvement</p>
                            </div>
                        </div>
                        <div className='flex gap-6 items-center'>
                            <div className=' w-20'>
                                    <Gauge
                                        percentage={parseFloat(((averagePercentages[key]?.healthy / averagePercentages[key]?.total) * 100).toFixed(2)) > 100 || parseFloat(((averagePercentages[key]?.healthy / averagePercentages[key]?.total) * 100).toFixed(2)) < 0 ? 0 : parseFloat(((averagePercentages[key]?.healthy / averagePercentages[key]?.total) * 100).toFixed(2))} // Set percentage based on the average percentage for the key
                                        lableOn={false}
                                    />
                            </div>
                            <div >
                                <p className=' font-bold'>TIM</p>
                                <p>50/100</p>
                            </div>
                        </div>
                        <div className='flex gap-6 items-center'>
                            <div className=' w-20'>
                                <Gauge
                                    percentage={70}
                                    lableOn={false}
                                />
                            </div>
                            <div >
                                <p className=' font-bold'>TIM</p>
                                <p>50/100</p>
                            </div>
                        </div> */}
                    </div>
                </div>
            }
            {/* End of quick info */}
        </div>
    )
}

export default HealthOverview;