import { useState, useEffect } from 'react';
import HealthOverviewContext from './HealthOverviewContext';
import { SpaceIOService } from 'auth-utils'
import 'primeicons/primeicons.css';
import moment from 'moment';
import {Tag} from "freespace-react-components";


const HealthOverviewProvider = ({ children }) => {

    let error;
    
    const [deviceHealth,setDeviceHealth ] = useState([]);
    const [deviceDetails, setDeviceDetails] = useState([]);
    const [productInfo, setProductInfo]= useState([]);
    const [productType, setProductType] = useState(null);

    const floorId = localStorage.getItem('FloorId');

    const DevicesDetails = async (floorId) => {
        const init = {
            method: 'get',
            maxBodyLength: Infinity,
            url: `${process.env.PROD_WS_BASE_URL}/api/admin/locations/${floorId}/devices`,
            headers: {
                'accept': 'application/json, text/javascript, */*; q=0.01',
            }
        };
        try {
            const response = await SpaceIOService.request(
                `${process.env.PROD_WS_BASE_URL}/api/admin/locations/${floorId}/devices`
                , init
            );
            const responseData = await response.json();
            return responseData;
        } catch (error) {
            console.error('An error occurred in the axios request:', error);
        }
    };

    const productDetails = async () => {
        const init = {
            method: 'get',
            maxBodyLength: Infinity,
            url: `${process.env.PROD_WS_BASE_URL}/api/admin/searchProducts`,
            headers: {
                'accept': 'application/json, text/javascript, */*; q=0.01',
            }
        };
        try {
            const response = await SpaceIOService.request(
                `${process.env.PROD_WS_BASE_URL}/api/admin/searchProducts`
                , init
            );
            const responseData = await response.json();
            return responseData;
        } catch (error) {
            console.error('An error occurred in the axios request:', error);
        }
    }

    const fetchDeviceHealthStatus = async (deviceAssetId) =>{
        // console.log("asset id",deviceAssetId)
        if (deviceAssetId.length > 0) {
            let data = JSON.stringify(deviceAssetId);
            let config = {
                method: 'post',
                maxBodyLength: Infinity,
                url: `${process.env.SPOT_BASE_URL}/assets/data/116/current`, 
                body: data
            };
            try {
                const response = await SpaceIOService.request(
                    `${process.env.SPOT_BASE_URL}/assets/data/116/current`
                    , config,true,true   
                );
                return await response.json();
            } catch (error) {
                console.error('An error occurred in the axios request:', error);
            }
        }
        else { return [] }
    };

    const networkDeviceDetails = async (floorId) => {

        // Device Asset Id 
        const init = {
            method: 'get',
            maxBodyLength: Infinity,
            url: `${process.env.PROD_WS_BASE_URL}/api/locations/${floorId}/raw-devices`,
            headers: {
                'accept': 'application/json, text/javascript, */*; q=0.01',
            }
        };
        try {
            const response = await SpaceIOService.request(
                `${process.env.PROD_WS_BASE_URL}/api/locations/${floorId}/raw-devices`
                , init
            );
            const responseData = await response.json();
            return responseData;
        } catch (error) {
            console.error('An error occurred in the axios request:', error);
        }
    };

    const fetchHealth = async (arrayOfFloorAssetId: string[]) => {
        if (arrayOfFloorAssetId.length > 0) {
            let data = JSON.stringify(arrayOfFloorAssetId);
            let config = {
                method: 'post',
                maxBodyLength: Infinity,
                url: `${process.env.SPOT_BASE_URL}/assets/data/10/current`, 
                body: data
            };
            try {
                const response = await SpaceIOService.request(
                    `${process.env.SPOT_BASE_URL}/assets/data/10/current`
                    , config,true,true   
                );
                return await response.json();
            } catch (error) {
                console.error('An error occurred in the axios request:', error);
            }
        }
        else { return [] }
    };


    //new function
    const fetchFloorid = async (productId) =>{
        // sapce Asset Id
        const init = {
            method: 'get',
            maxBodyLength: Infinity,
            url: `${process.env.PROD_WS_BASE_URL}/api/admin/${productId}/locations`,
            headers: {
                'accept': 'application/json, text/javascript, */*; q=0.01',
            }
        };
        try {
            const response = await SpaceIOService.request(
                `${process.env.PROD_WS_BASE_URL}/api/admin/${productId}/locations`
                , init
            );
            const responseData = await response.json();
            return responseData;
        } catch (error) {
            console.error('An error occurred in the axios request:', error);
        }
    }

    const fetchData = async () => {
        let finalData = [];
        let finalHealthData = [];
        let networkData = [];
        let arrayOfAssetId = [];
        let mergedData;
        let mergedDataArray;

        try{
            if(floorId){
                finalData = await DevicesDetails(floorId);
                networkData = await networkDeviceDetails(floorId);
                if (productType.includes('ONE')) {
                    arrayOfAssetId = networkData.map(networkinfo => networkinfo.assetId);
                } else {
                    arrayOfAssetId = finalData.map(deviceInfo => deviceInfo.assignedSpace?.assetId);
                }
            }
            else{
                const productData = await productDetails()
                const filteredData = productData.filter(product =>
                    productType.includes(product.name)
                );

                mergedData = filteredData.reduce((acc, product) => {
                    acc.push(product); // Push each filtered object into the accumulator array
                    return acc;
                }, []);

                const floorIdPromises = mergedData.map(async item => {
                    return await fetchFloorid(item.id);
                });
                
                const allFloorIds = await Promise.all(floorIdPromises);
                const combinedFloorIds = allFloorIds.flat();

                const uniqueFloorIds = combinedFloorIds.filter((value, index, self) => {
                    // Check if the current object already exists in the previous items
                    return index === self.findIndex((v) => 
                        v.name === value.name && v.id === value.id
                    );
                });

                const floorIdString = uniqueFloorIds.map(floor => floor.id).join(',');

                const allDeviceData = await Promise.all(
                    uniqueFloorIds.map(async (item) => {
                        let deviceData = await DevicesDetails(item.id);
                        return deviceData
                    })
                );

                let deviceDetail = allDeviceData.flat()

                const networkData = await networkDeviceDetails(floorIdString);
                let arrayOfAssetId;
                if (productType.includes('ONE')) {
                    arrayOfAssetId = networkData.map(networkinfo => networkinfo.assetId);
                } else {
                    arrayOfAssetId = deviceDetail.map(deviceInfo => deviceInfo.assignedSpace?.assetId);
                }

                mergedDataArray = deviceDetail.map(deviceDetailsFinale => {
                    const networkDataDisplay = networkData.find(network => network.id === deviceDetailsFinale.id);
                    if (networkDataDisplay) {
                        deviceDetailsFinale.networkData = networkDataDisplay;
                    }
                    return deviceDetailsFinale;
                });

                let deviceAssetIdArray = mergedDataArray.map(networkinfo => networkinfo.networkData?.assetId);

                const healthDataResults = await fetchDeviceHealthStatus(deviceAssetIdArray);

                mergedDataArray.forEach((deviceDetailsFinale) => {
                    const healthStatusDisplay = healthDataResults.find(
                      (status) => status.id === deviceDetailsFinale?.networkData?.assetId
                    );
                                    
                    if (healthStatusDisplay) {
                      deviceDetailsFinale.healthStatusData = healthStatusDisplay;
                    } else {
                      console.warn(`No health status found for asset ID: ${deviceDetailsFinale?.networkData?.assetId}`);
                      deviceDetailsFinale.healthStatusData = null; 
                    }
                });

                finalHealthData = await fetchHealth(arrayOfAssetId);
                return {
                    mergedData,
                    mergedDataArray,
                    finalHealthData
                }

            }
        }
        catch(e){
            // console.log(e)
        }
    };

    useEffect(() => {
        const fetchDataAsync = async () => {
            try{
                if (productType && productType.length > 0) {
                    const data = await fetchData();
                
                    // Process the fetched data here, for example:
                    setDeviceHealth(data.finalHealthData);
                    setDeviceDetails(data.mergedDataArray);
                    setProductInfo(data.mergedData);
                }
            }
            catch(e){
                // console.log('e',e)
            }
            
        };
    
        fetchDataAsync();
    }, [productType]);

    

    if (error) {
        return <div>Error: {error}</div>;
    }

    return (
        <HealthOverviewContext.Provider
            value={{
                
                deviceDetails:deviceDetails,
                deviceHealth:deviceHealth,
                productInfo:productInfo,
                products:productType,
                setProductType,
            }}>
            {children}
        </HealthOverviewContext.Provider >
    );
};

// Skip prop validation by setting propTypes to null
HealthOverviewProvider.propTypes = null;

export default HealthOverviewProvider;








