import "freespace-react-components/style.css";
import './index.css';
import { BrowserRouter as Router, Route, Routes, useLocation } from "react-router-dom";
import HealthOverview from "./pages/HealthOverview/HealthOverview";
import HealthOverviewProvider from './context/HealthOverviewProvider';
import AccountHealthOverview from './pages/HealthOverview/AccountWiseHealth';
import AccountHealthProvider from './context/AccountHealthOverviewProvider';
import LocationHealthOverview from './pages/HealthOverview/LocationDetails';
import LocationHealthProvider from './context/LocationHealthProvider';
import { AdminHeader, Tooltip } from "freespace-react-components";
import WorldIcon from './assets/images/SVGComponents/World';
import { useEffect, useState } from "react";

function HeaderWithRouter() {
    const userAccountInfo = JSON.parse(localStorage.getItem('spaceio-user'));
    const [adminHeaderText, setAdminHeaderText] = useState('');
    const location = useLocation(); // Get the location object

    // Function to handle input change
    const handleInputChange = (data) => {
        // console.log(data); // Update searchValue state with the new input value
    };

    function formatHeaderText(path) {


        if (path.startsWith('/')) {
            path = path.substring(1);
        }
        // Split the path by hyphen and capitalize each word
        const words = path.split('-').map(word => word.charAt(0).toUpperCase() + word.slice(1));
        // console.log(words);
        // Join the words with a space
        return words.join(' ');
    }


    // Update headerText based on the path
    useEffect(() => {
        const pathname = location.pathname.replace("/global/", "");
        // console.log((pathname));
        setAdminHeaderText(formatHeaderText(pathname));
    }, [location]); // Add location as a dependency

    return (
        <AdminHeader
            headerText={adminHeaderText}
            isNotification={true}
            userIcon={
                <>
                    <img className='userIcon w-full rounded-full h-8' src={userAccountInfo ? userAccountInfo.profileImage : ''} alt="userIcon" />
                    <Tooltip
                        position={'top'}
                        event={'hover'}
                        disabled={false}
                        target={'.userIcon'}
                    >
                        <p className='text-xs p-0.5'>Profile</p>
                    </Tooltip>
                </>
            }
            accountIcon={adminHeaderText === 'Health Overview'
                ? <>
                    <span className="accountIcon"><WorldIcon /> </span>
                    <Tooltip
                        position={'top'}
                        event={'hover'}
                        disabled={false}
                        target={'.accountIcon'}
                    >
                        <p className='text-xs p-0.5'>All Accounts</p>
                    </Tooltip>
                </>
                : <>
                    <img className="accountIcon w-full h-8" src={userAccountInfo.account.logoUrl} alt="AccountIcon" />
                    <Tooltip
                        position={'top'}
                        event={'hover'}
                        disabled={false}
                        target={'.accountIcon'}
                    >
                        <p className='text-xs p-0.5'>{userAccountInfo.account.name}</p>
                    </Tooltip>
                </>
            }
        />
    );
}

export default function Root() {
    return (
        <Router basename="/global">
            <div className="flex flex-col w-full h-screen bg-bgColor">
                <HeaderWithRouter />
                <Routes>
                    <Route path="/health-overview" element={
                        <HealthOverviewProvider>
                            <HealthOverview />
                        </HealthOverviewProvider>} />
                    <Route path='/account-health-overview' element={
                        <AccountHealthProvider>
                            <AccountHealthOverview />
                        </AccountHealthProvider>}
                    />
                    <Route path='/device-health' element={
                        <LocationHealthProvider>
                            <LocationHealthOverview />
                        </LocationHealthProvider>
                    }
                    />
                </Routes>
            </div>
        </Router>
    );
}
