// import React from 'react'
import React, { useState, useEffect,useRef, useContext } from 'react'
import { InsightText, TreeTable, Button, Gauge, Tooltip, PageLoader } from "freespace-react-components";
import HealthOverviewContext from '../../context/HealthOverviewContext';


const AccountWiseHealth = () => {
    const { 
        quickInfoData, 
        overallInfo, 
        insightText, 
        liveTreeAPIData, 
        Products 
    } = useContext<any>(HealthOverviewContext);
    const parentRef = useRef(null);



    const [showQuickInfo, setShowQuickInfo] = useState(false);
    const [liveTreeData, setLiveTreeData] = useState(liveTreeAPIData);
    const [averagePercentages, setAveragePercentages] = useState<object>({})
    const [overallPercentage, setOverallPercentage] = useState();
    const [accountName, setAccountName] = useState();
    const [parentHeight, setParentHeight] = useState(0);

    const columns = [
        { 
            field: 'Name', 
            header: 'Name', 
            className:'text-xs whitespace-nowrap', 
            fixShowCol: true, 
            frozen:true, 
            expander: true, 
            style: { 'width':'auto',height:'50px'},
            // colBody: (sampleData: any) => nameBodyTemplate(sampleData) 
        },
        { 
            field: 'Type', 
            header: 'Type',
            className:'text-xs whitespace-nowrap', 
            fixShowCol: true, 
            style: { 'width':'auto',height:'50px'},
            
        },
        { 
            field: 'Id', 
            header: 'Id',  
            className:'text-xs whitespace-nowrap', 
            fixShowCol: true, 
            style: { 'width':'auto',height:'50px'}, 
        },
        
        { 
            field: 'Overall', 
            header: 'Overall', 
            sortable:true, 
            className:'text-xs whitespace-nowrap', 
            style: { 'width':'auto',height:'50px'},
            // colBody: (sampleData:any) => OverallDataTemplate(sampleData)
        },
        { 
            field: 'FLO', 
            header: 'FLO',
            sortable:true, 
            className:'text-xs whitespace-nowrap', 
            style: { 'width':'auto',height:'50px'} ,
            // colBody: (sampleData:any) => productDataTemplate(sampleData)
        },
        { 
            field: 'BAT-FLO', 
            header: 'BAT-FLO',
            sortable:true, 
            className:'text-xs whitespace-nowrap', 
            style: { 'width':'auto',height:'50px'} 
        },
        { 
            field: 'BAT-TIM',
            header: 'BAT-TIM', 
            sortable:true, 
            className:'text-xs whitespace-nowrap', 
            style: { 'width':'auto',height:'50px'}
        },
        { 
            field: 'TIM+', 
            header: 'TIM+', 
            sortable:true, 
            className:'text-xs whitespace-nowrap', 
            style: { 'width':'auto',height:'50px'} 
        },
        { 
            field: 'TIM', 
            header: 'TIM', 
            sortable:true, 
            className:'text-xs whitespace-nowrap', 
            style: { 'width':'auto',height:'50px'} 
        },
        { 
            field: 'ONE', 
            header: 'ONE', 
            sortable:true, 
            className:'text-xs whitespace-nowrap', 
            style: { 'width':'auto',height:'50px'} 
        }
    ];

    useEffect(() => {
        setAveragePercentages(quickInfoData)
        setOverallPercentage(overallInfo)
    }, [quickInfoData, overallInfo])

    useEffect(() => {
        const userInfo = JSON.parse(localStorage.getItem('spaceio-user'));
        setAccountName(userInfo?.account?.name || 'Unknown Account');
    }, []);

    useEffect(() => {
        if (parentRef.current) {
          // Get the height of the parent element
          const height = parentRef.current.getBoundingClientRect().height;
          setParentHeight(height);
        }
    
        // Optional: Listen to window resize events in case the height changes
        const handleResize = () => {
          if (parentRef.current) {
            const height = parentRef.current.getBoundingClientRect().height;
            setParentHeight(height);
          }
        };
    
        window.addEventListener('resize', handleResize);
    
        // Cleanup event listener on unmount
        return () => {
          window.removeEventListener('resize', handleResize);
        };
      }, []);

      console.log('parentHeight',parentHeight)


    useEffect(() => {
        if (liveTreeAPIData) {
            setLiveTreeData(liveTreeAPIData);
        }
    }, [liveTreeAPIData]);

    console.log('liveTreeAPIData',liveTreeAPIData)


    const values = [
        {
          key: '0',
          data: { Name: 'Netflix', Type: 'REGION', Id: '5218',liveStatus:true },
          children: [
            {
              key: '0-0',
              data: { Name: 'USA', Type: 'REGION', Id: '6156' ,liveStatus:true},
              children:[
                {
                    key: '0-0-0', 
                    data: { Name: 'Los gatos', Type:'LOCATION', Id: '6157',liveStatus:true},
                    children: [
                        {
                            key: '0-0-0-0',
                            data: { Name: '121 Albright Wy', Type:'BUILDING', Id:'6158',Overall:'100',
                                liveStatus:true},
                            children: [
                                {
                                    key: '0-0-0-0-0',
                                    data: { 
                                        Name: 'F4 Floor',Type:'FLOOR', Id:'6162',Overall:'3', 
                                        // risk:overallRiskInfo(3),
                                        falutyPercentage:'2',FLO:'55',
                                        'BAT-FLO':'5',TIM:'25','BAT-TIM':'2','TIM+':'35',liveStatus:true}
                                },
                                {
                                    key: '0-0-0-0-1',
                                    data: { 
                                        Name:'F3 Floor', Type:'FLOOR', Id:'6163',Overall:'30',
                                        // risk:overallRiskInfo(30),
                                        falutyPercentage:'10', FLO:'5',
                                        'BAT-FLO':'100',TIM:'96','BAT-TIM':'2','TIM+':'50'}
                                }
                            ]
                        }
                    ]
                },
                {
                    key:'0-0-1',
                    data: {Name: 'Mexico', Type:'LOCATION', Id:'6517',liveStatus:true},
                    children: [
                        {
                            key:'0-0-1-0',
                            data: { Name: 'Avenida Paseo de la Reforma 483', Type:'BUILDING', Id: '6518',Overall:'100',
                                liveStatus:true},
                            children: [
                                {
                                    key:'0-0-1-0-0',
                                    data: { Name:'40th Floor', Type:'FLOOR', Id:'6524',Overall:'100',
                                        // risk:overallRiskInfo(3),
                                        falutyPercentage:'9',FLO:'10','BAT-FLO':'95',
                                        TIM:'2','BAT-TIM':'20','TIM+':'0',liveStatus:true}
                                }
                            ]
                        }
                    ]
                },
               
                
              ]
            }
          ],
        },
    ];
    



    return (
        <div ref={parentRef} className="flex w-full h-screen">
            <div className="flex flex-col w-full"  >
                <div className=" flex w-full h-full divide-x divide-dividerLines bg-webBg">
                    <div className="w-full flex flex-col justify-between ">
                        <div className={`${showQuickInfo ? 'w-28' : 'w-full'}`} >
                            <div style={{backgroundColor: 'rgba(243, 252, 248, 1)',boxShadow: '0px 4px 4px 0px #0000000F'}} className={`flex bg-accent1Rollover h-16 p-2 justify-between overflow-x-auto [&::-webkit-scrollbar]:hidden [-ms-overflow-style]:none [scrollbar-width]:none`}>
                                {insightText.map(item => (
                                    <InsightText
                                        key={item.id}
                                        primaryText={
                                            <div>
                                                <p className='text-xs' style={{color:'#1E253E',fontSize:'12px'}}>{`${item.id} - ${item.name} `}</p>
                                            </div>
                                        }
                                        secondaryText={
                                            <div className='flex flex-col text-xs'>
                                                <p className='text-xs' style={{color:'#6D7492',fontSize:'12px'}}>{`${item.anchorAddress.split(',')[0]?.trim()} - ${item.anchorAddress.split(',')[1]?.trim()}`}</p>
                                            </div>
                                        }
                                        contentInput={
                                            <p className="text-xs" style={{color:'#FF5C5C',fontSize:'12px',fontWeight:'700'}}>
                                                {item.faultyPercentage}%
                                            </p>
                                        }
                                    />
                                ))}
                            </div>
                            {liveTreeData ? (
                                <TreeTable
                                    data={liveTreeAPIData}
                                    columns={columns} 
                                    globalFilterFields={['Name','Overall']}  
                                    frozenWidth='300px'
                                    scrollHeight='h-full'
                                    expandAll={false}
                                />

                            ) : 
                            <PageLoader loading={true} />
                            }
                        </div>
                    </div>

                    {
                        showQuickInfo && (
                            <div className='w-1/5 bg-customGray flex flex-col items-start' style={{ width: '23%', gap: '17px', borderRight: '2px' }}>
                                <p className='font-serif font-medium text-base pl-3 ' style={{ color: '#1E253E',paddingTop:'14px',paddingLeft:'13px'}}>Quick info</p>
                               
                                <div className=" text-xs font-medium items-center flex flex-col w-full" >
                                    <div className='pb-2' style={{ width: '10rem' }}>
                                        <Gauge
                                            percentage={10}
                                            lableOn={true}
                                            left={<p className='text-xs justify-center' style={{color:'#6D7492',fontWeight:'700'}}>Low Risk</p>}
                                            right={<p className='text-xs justify-center' style={{color:'#6D7492',fontWeight:'700'}}>High Risk</p>}
                                        />
                                    </div>
                                    <div className=" text-sm items-center flex flex-col" style={{color:'#555555',fontWeight:'700'}}>
                                        <span
                                            className="font-semibold font-sans"
                                            style={{ color:'#555555', textAlign: "center" }}>
                                            {accountName} is at
                                        </span>
                                        <span className="font-semibold font-sans text-[#555555]  " style={{color:'#555555'}}>
                                            {Math.floor(parseFloat(overallPercentage))}% {overallPercentage ? (overallPercentage > 30 ? 'Low Risk' : 'High Risk') : 'No Information Available'}
                                        </span>
                                    </div>
                                </div>
                                <hr className="w-full border-t border-gray-300" />
                                {Object.keys(averagePercentages).map((key) => (
                                    <>
                                        {averagePercentages[key]?.total > 0 && (
                                            <div className="flex gap-4 items-center"style={{ paddingTop: '10px', paddingLeft: '23px' }} > 
                                           
                                                <div style={{ width: '70px' }} >
                                                    <Gauge
                                                        percentage={parseFloat(((averagePercentages[key]?.healthy / averagePercentages[key]?.total) * 100).toFixed(2)) > 100 || parseFloat(((averagePercentages[key]?.healthy / averagePercentages[key]?.total) * 100).toFixed(2)) < 0 ? 0 : parseFloat(((averagePercentages[key]?.healthy / averagePercentages[key]?.total) * 100).toFixed(2))} // Set percentage based on the average percentage for the key
                                                        lableOn={false} // Assuming this is a prop for the Gauge component to toggle label visibility
                                                    />
                                                </div>
                                                <p className="font-sans text-sm font-bold " style={{color: '#1E253E'}}>{averagePercentages[key]?.total > 0 ? Math.floor((averagePercentages[key]?.healthy / averagePercentages[key]?.total) * 100) : 0}% {key} </p>
                                            </div>
                                        )}
                                    </>
                                ))}
                            </div>
                        )
                    }
                </div>

            </div>
        </div>
    )

}

export default AccountWiseHealth;