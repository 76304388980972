import { useEffect, useContext, useState } from 'react';
import moment from 'moment';
import { Table, PageLoader, Tag, Overlay, PopUp } from "freespace-react-components";
import HealthOverviewContext from '../../context/HealthOverviewContext';
import { useLocation } from 'react-router-dom';
import TroubleShootsSteps from '../../Json/TroubleshootingSteps.json';

const LocationWiseHealth = () => {
    const { setProductType, deviceDetails, deviceHealth, productInfo, products } = useContext<any>(HealthOverviewContext);
    const dataFromPage = useLocation();

    const [devicesInfo, setDevicesInfo] = useState(null);
    const [visible, setVisible] = useState<boolean>(false);
    const [dismissable, setDismissable] = useState(true);
    const [product, setProduct] = useState(null);
    const [errorMessage, setErrorMessage] = useState(null);
    const [error, setError] = useState(null);
    const [errorInfo, setErrorInfo] = useState(null);
    const [i2cError, setI2CError] = useState(null)
    const [battery, setBattery] = useState(null);
    const [steps, setSteps] = useState([]);


    useEffect(() => {
        if (dataFromPage.state.ProductType === 'FLO') {
            setProductType(['FLO', 'POE FLO']);
        } else if (dataFromPage.state.ProductType === 'TIM') {
            setProductType(['TIM', 'POE TIM']);
        } else if (dataFromPage.state.ProductType === 'TIM+') {
            setProductType(['TIM+', 'POE TIM+']);
        } else {
            setProductType([dataFromPage.state.ProductType])
        }
    }, [setProductType]);


    useEffect(() => {

        try {
            let filteredArray = [];
            if (products) {
                if (!deviceDetails) {
                    throw new Error("deviceDetails is undefined");
                }
                const filteredProductData = transformData(deviceDetails, productInfo, deviceHealth, products);
                filteredArray = filteredProductData.filter(item => products.includes(item.Product));
            } else {
                filteredArray = transformData(deviceDetails, productInfo, deviceHealth, products);
            }
            setDevicesInfo(filteredArray); // Update the state
        } catch (e) {
            console.error(e);
        }


    }, [deviceDetails, deviceHealth, productInfo, products]);


    const hideDialog = () => {
        setVisible(!visible); // Hide the modal when closed
    };

    useEffect(() => {

        if (errorMessage && product && error !== null) {
            handleClick(errorMessage, product, error)
        }

    }, [errorMessage, product, error])

    useEffect(() => {
        console.log("i2c Error", i2cError);

    }, [i2cError])



    //Handle popup error message logic
    const handleClick = (errorMessage, product, error) => {
        const batteryDevices = ['ONE', 'TIM', 'FLO', 'BAT-FLO', 'BAT-TIM'];
        const poweredDevices = ['PAM', 'TIM+', 'POE TIM+', 'POE FLO', 'POE TIM'];

        if (TroubleShootsSteps.products[product] && TroubleShootsSteps.products[product][errorMessage]) {
            const newSteps = TroubleShootsSteps.products[product][errorMessage][0]?.steps || [];
            setSteps(newSteps); // Update the steps state
        }


        if (error === 4 && errorMessage === 'Heart Beat Miss') {
            setErrorMessage('Heart Beat Miss');
            if (batteryDevices.includes(product)) {
                setErrorInfo(['Last updated and last presence in Between 2 to 20 days'])
            }
            else {
                setErrorInfo(['Last updated in between 2 to 20 days'])
            }
        }
        else if (error === 2 && errorMessage === 'Missing SOM') {
            setErrorMessage('Missing SOM');
            if (batteryDevices.includes(product)) {
                setErrorInfo(['Last updated older than 20 days']);
            }
        }
        else if (error === 3 && errorMessage === 'Occupancy Check') {
            setErrorMessage('Occupancy Check');
            if (batteryDevices.includes(product)) {
                setErrorInfo(['Last Presence is older than 20 days']);
            }
        }
        else if (error === 1 && errorMessage === 'Needs Urgent Attention') {
            setErrorMessage('Needs Urgent Attention');
            if (batteryDevices.includes(product)) {
                if (i2cError === true) {
                    setErrorInfo([`I2C Error: ${i2cError}`])
                }
                else if (battery < 3) {
                    setErrorInfo([`Battery : ${Math.floor(battery * 10) / 10} (Unhealthy)`])
                }
                else if (i2cError === true && battery > 3) {
                    setErrorInfo([`I2C Error: ${i2cError}`, `Battery : ${Math.floor(battery * 10) / 10} (Unhealthy)`])

                }
                else {
                    setErrorInfo([`I2C Error: ${i2cError}`, `Battery : ${Math.floor(battery * 10) / 10} (Healthy)`, 'Last updated and last presence older than 20 days'])
                }

            }
            else if (poweredDevices.includes(product)) {
                console.log('powered device')
                console.log(i2cError)
                if (i2cError === true) {
                    console.log('into i2cError ')
                    setErrorInfo([`I2C Error: ${i2cError}`])
                }
                else {
                    setErrorInfo([`I2C Error: ${i2cError}`, 'Last updated and last presence older than 20 days'])
                }
            }
        }
    }


    const transformData = (data, productWiseDetails, healthDataArrayInfo, products) => {
        return data.map(item => {
            const productId = item?.assignedSpace?.device?.hardware?.productId || '';
            const matchedProduct = productWiseDetails.find(product => product.id === productId);
            let healthData = null;

            // Determine the health data based on device type
            if (products.includes('ONE')) {
                healthData = healthDataArrayInfo.filter((data) => data.id === item.networkData?.assetId);
            } else {
                healthData = healthDataArrayInfo.filter((data) => data.id === item.assignedSpace?.assetId);
            }
            item.healthData = healthData;

            // Function to format Unix timestamp
            const formatUnixTimestamp = (timestamp) => {
                if (!timestamp) return <div></div>;

                const formattedDate = moment.unix(timestamp).format('MM-DD');
                const formattedTime = moment.unix(timestamp).format('HH:mm:ss');

                return (
                    <div>
                        <p>{formattedDate}</p>
                        <p className='text-gray-400'>{formattedTime}</p>
                    </div>
                );
            };

            console.log(healthData[0]?.i2cError)
            const i2cStatus = healthData[0]?.i2cError ? healthData[0]?.i2cError.toString() : ''



            const riskLevels = {
                1: { label: 'High', severity: 'danger' },
                2: { label: 'Medium', severity: 'warning' },
                3: { label: 'Medium', severity: 'warning' },
                4: { label: 'Low', severity: 'info' },
                0: { label: 'Healthy', severity: 'success' }
            };


            // Function to get the risk tag based on errorCode
            const getRiskTag = (errorCode, errorReason, productName, i2cerror) => {

                const riskInfo = riskLevels[errorCode];
                if (riskInfo) {
                    if (riskInfo.label === 'Healthy') {
                        return (
                            <div>
                                <Tag value={riskInfo.label} severity={riskInfo.severity} />
                            </div>

                        );
                    }
                    return (
                        <>
                            <Overlay trigger={<Tag value={riskInfo.label} severity={riskInfo.severity} />}
                                children={
                                    <>
                                        <div className='text-xs flex'>
                                            <span className='font-bold'>Errors:  </span>
                                            <span className='ml-2'>{errorReason}</span>
                                        </div>
                                        <p onClick={() => { setVisible(true); setErrorMessage(errorReason), setI2CError(i2cerror), setProduct(productName), setError(errorCode), handleClick }}
                                            className='cursor-pointer pt-2' style={{ color: '#FF8A66' }}>
                                            Click here to view more details
                                        </p></>
                                }
                                dismissable={true} />
                        </>
                    )
                }

                return null;
            };

            // Get errorCode from healthStatusData and determine the tag
            setErrorMessage(item?.healthStatusData?.errorReason)

            setBattery(healthData[0]?.batteryVtg)

            const errorReason = item?.healthStatusData?.errorReason
            const errorCode = item?.healthStatusData?.errorCode;
            const productName = matchedProduct ? matchedProduct.name : productId
            const i2cerror = healthData[0]?.i2cError
            const riskTag = getRiskTag(errorCode, errorReason, productName, i2cerror);


            return {
                'Product': matchedProduct ? matchedProduct.name : productId,
                'Install ID': item?.id || '',
                'Serial ID': item?.assignedSpace?.device?.blessId || '',
                'Space Name': item?.displayName || '',
                'Marker ID': item?.assignedSpace?.serial || '',
                'Category': item?.assignedSpace?.category.name || '',
                'Space ID': item?.assignedSpace?.id || '',
                'Presence': formatUnixTimestamp(healthData[0]?.lastPresence),
                'Heart Beat': formatUnixTimestamp(healthData[0]?.heartbeatAck),
                'Last Frame': formatUnixTimestamp(healthData[0]?.lastFrame),
                'I2C Error': i2cStatus,
                'Network': healthData[0]?.wifiPara,
                'Build': healthData[0]?.buildVersion,
                'TOy': item?.networkData?.network?.incidentsOfTimeoutsYesterday || '0',
                'TOt': item?.networkData?.network?.incidentsOfTimeoutsToday || '0',
                'Risk': riskTag,  // Updated to use Tag component
            };
        });
    };


    const handleSelectedCell = async (cell) => {
        // console.log(cell)
    };


    const tagConditions = [
        {
            key: 'TOy',
            condition: (value) => value > 0 ? 'danger' : 'success'
        },
        {
            key: 'TOt',
            condition: (value) => value > 0 ? 'danger' : 'success'
        },
    ];


    return (
        <div className=' flex flex-auto overflow-hidden'>
            {
                devicesInfo && devicesInfo.length > 0
                    ?
                    <div className='flex-auto overflow-hidden mt-6'>
                        <Table
                            data={devicesInfo}
                            minWidth={'200rem'}
                            columns={[]}
                            // commonColumsName={['Product']}
                            // columnsName={['Install ID', 'Serial ID', 'Space Name', 'Marker ID', 'Category', 'Space ID',
                            //     'Presence', 'Heart Beat', 'Last Frame', 'I2C Error', 'Network', 'Build', 'TOy', 'TOt', 'Risk']}
                            // frozenCol={['Product', 'Install ID', 'Risk']}
                            setSelectedCell={handleSelectedCell}
                            globalFilterFields={['Product', 'Install ID', 'Space ID', 'Serial ID']}
                            downloadOption={false}
                            pagination={true}
                            numberOfRows={20}
                            rowsPerPageOptions={[25, 50, 100]}
                        />
                    </div>
                    : <PageLoader
                        loading={true}
                    />
            }
            <PopUp
                content={
                    <div>
                        <p className='text-primaryText font-bold text-sm' style={{ paddingBottom: '5px' }}>Errors: {errorMessage} </p>
                        <div className='text-sm text-primaryText h-auto w-full' style={{ padding: '10px', backgroundColor: '#FFE0E0' }}>
                            {/* <p className='text-sm text-bold'> {errorMessage}</p> */}
                            {errorInfo && errorInfo.length > 0 && errorInfo.map((info, index) => (
                                <p key={index}>{index + 1}. {info}</p>
                            ))}
                        </div>

                        <div className='flex flex-col'>
                            <p className='text-sm font-bold text-primaryText' style={{ paddingTop: '10px' }}>Troubleshooting Tips</p>
                            <span className='text-sm' style={{ paddingTop: '10px' }}>A quick fix for troubleshooting:</span>

                            <div className='flex flex-col text-sm' style={{ paddingTop: '10px' }}>
                                {steps && steps.length > 0 && steps.map((info, index) => (
                                    <span style={{ marginBottom: '5px' }} key={index}>{index + 1}. {info}</span>
                                ))}
                            </div>

                            <span className='text-sm' style={{ width: '429px', marginTop: '10px', marginBottom: '10px' }}>Battery Operated device share different method of power cycling than the main or PoE powered ones.</span>
                            <span className='cursor-pointer pt-2 text-sm' style={{ color: '#FF8A66' }}>Click the below link for more information on Power cycling the devices.</span>
                        </div>
                    </div>
                }
                onHide={() => { if (!visible) return; setVisible(false); }}
                // header={<div className='justify-center'><i className='pi pi-info-circle text-accent2'></i></div>} 
                header={
                    <div className='flex flex-col justify-center items-center'>
                        <i className='pi pi-info-circle' style={{ color: '#748ADF' }}></i>
                        <span className='text-primaryText font-bold text-sm ' style={{ marginTop: '0.5rem' }}>Errors & Troubleshooting Tips!</span>
                    </div>
                }
                closeOnEscape={false}
                draggable={false}
                maximizable={false}
                modal={true}
                position={'center'}
                blockScroll={false}
                visible={visible}
            />
        </div>

    )
}

export default LocationWiseHealth;